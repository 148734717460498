
import { ref, defineComponent } from "vue";
export default defineComponent({
  setup() {
    const competences = ref([
      "Javascript",
      "Typescript",
      "Vue",
      "Go",
      "HTML",
      "CSS",
    ]);
    return {
      competences,
    };
  },
});

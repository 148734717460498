
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "projects-list",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = ref([
      {
        title: "Top Authors",
        text: "Mark, Rowling, Esther",
      },
      {
        title: "Popular Authors",
        text: "Randy, Steve, Mike",
      },
      {
        title: "New Users",
        text: "John, Pat, Jimmy",
      },
      {
        title: "Active Customers",
        text: "Mark, Rowling, Esther",
      },
      {
        title: "Bestseller Theme",
        text: "Disco, Retro, Sports",
        space: "",
      },
    ]);

    return {
      list,
    };
  },
});

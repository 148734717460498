import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col-md-8" }
const _hoisted_3 = { class: "col-md-4" }
const _hoisted_4 = { class: "row mb-3" }
const _hoisted_5 = { class: "col-md-4 mb-3" }
const _hoisted_6 = { class: "col-md-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardProfileMember = _resolveComponent("CardProfileMember")!
  const _component_CompetenceCard = _resolveComponent("CompetenceCard")!
  const _component_ProjectList = _resolveComponent("ProjectList")!
  const _component_TicketsTable = _resolveComponent("TicketsTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CardProfileMember)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CompetenceCard)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ProjectList)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_TicketsTable)
      ])
    ])
  ], 64))
}